import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { TablePagination } from '@mui/material';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from '../../../components/UI/ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onComment, onSort, pagination, handleChangePage, handleChangeRowsPerPage }) => {
  const { formatDateTime } = useUserTimeZone();
  const { handleSort, sortState } = useSortHook(data, ['createdAt', 'startDate']);

  const headData = ['Advt. ID', 'Name of Advt.', 'Created Date', 'Published Date', 'Advt. Status', 'Actions'];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: (ex) => handleSort(ex, onSort),
          sortHeadMap: ['adId', 'adName', 'createdAt', 'startDate', 'status', ''],
        }}
        headData={headData}
      >
        {data?.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
        {data?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.adId}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.adName}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.startDate)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.status}</p>
            </CustomTableCellForBody>
            {/* <CustomTableCellForBody align="center">
              <p style={{ ...tableCellPStyle }}>{e?.sectionsWeb?.map((ex) => ex?.name)?.join(', ')}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.status ? 'Active' : 'Inactive'}</p>
            </CustomTableCellForBody> */}
            {/* <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody> */}
            <CustomTableCellForBody align="center">
              <ThreeDotMenu onEdit={() => onEdit(e)} onComment={() => onComment(e)} />
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
        component="div"
        count={pagination?.total ?? 0}
        rowsPerPage={pagination?.perPage}
        page={pagination?.currentPage - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default TableView;
