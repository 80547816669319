import { TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// import AddIcon from '@mui/icons-material/Add';
// import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './AdvertisementList.css';

import { getAdvertisementListFromVendor } from '../../../services/Service';
// import CustomButton from '../../../components/UI/CustomButton';
import TableView from './TableView';
import AdvertisementListModal from './AdvertisementListModal';
import Inlineloader from '../../../components/Loader/InlineLoader';
import useDebounce from '../../../hooks/useDebounce';
import AdvertisementActionReasonModal from './AdvertisementActionReasonModal';

const AdvertisementList = () => {
  const [modalOpen, setModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });
  const [rawData, setRawData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    orderby: 'created_at',
    order: 'desc',
    search: '',
  });
  const [pageCommentModal, setPageCommentModal] = useState({
    open: false,
    data: [],
  });

  const onOpenCommentModal = (data) => {
    const advertisementActionReason = data?.advertisementActionReason || [];
    setPageCommentModal({
      open: true,
      data: advertisementActionReason,
    });
  };
  const onCloseCommentModal = () => {
    setPageCommentModal({
      open: false,
      data: [],
    });
  };

  const fetchData = async (filterObj, flag) => {
    try {
      !flag && setLoading(true);
      const res = await getAdvertisementListFromVendor(filterObj);
      if (res.status === 200) {
        setRawData(res.data.data);
        setTotalData(res.data.total ?? 0);
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    } finally {
      !flag && setLoading(false);
    }
  };
  const onModalOpenEdit = (data) => {
    setModalOpen({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onModalClose = () => {
    setModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData(filter, true);
  };

  const onSort = (sortObj) => {
    const orderBy = sortObj.orderBy ? (sortObj.orderBy === 'createdAt' ? 'created_at' : sortObj.orderBy) : '';
    setFilter((prev) => ({ ...prev, orderby: orderBy, order: sortObj?.order ?? '' }));
    // fetchData({ ...filter, orderby: orderBy, order: sortObj?.order ?? '' });
  };

  const handleChangePage = (_, value) => {
    setFilter((prev) => ({ ...prev, page: value + 1 }));
    // fetchData({ ...filter, page: value + 1 });
  };
  const handleChangeRowsPerPage = (e) => {
    setFilter((prev) => ({ ...prev, page: 1, limit: e.target.value ?? 25 }));
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter]);

  useDebounce(
    () => {
      setFilter((prev) => ({ ...prev, search: searchValue }));
    },
    [searchValue],
    1500
  );

  return (
    <>
      <Helmet>
        <title>Advertisement List - SafalSubscriptions</title>
      </Helmet>
      {loading && <Inlineloader />}
      <div id="ApplicationListBusiness">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Advertisement List
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div">
            <TextField
              sx={{ width: '200px', ml: '12px' }}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              type="search"
              id="search-input"
              size="small"
              label="Search"
              variant="outlined"
            />
          </div>
        </div>
        <TableView
          pagination={{
            total: totalData,
            perPage: filter.limit,
            currentPage: filter.page,
          }}
          onEdit={onModalOpenEdit}
          onComment={onOpenCommentModal}
          data={rawData}
          onSort={onSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
      <AdvertisementListModal
        onComment={onOpenCommentModal}
        data={modalOpen?.data}
        onClose={onModalClose}
        open={modalOpen?.open}
      />
      <AdvertisementActionReasonModal
        data={pageCommentModal?.data}
        open={pageCommentModal?.open}
        onClose={onCloseCommentModal}
      />
    </>
  );
};

export default AdvertisementList;
