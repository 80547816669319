import * as React from 'react';

import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  py: 2,
  px: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '200px',
};

export default function AdvertisementActionReasonModal({ open, onClose, data }) {
  const { formatDateTime } = useUserTimeZone();
  const handleClose = (flag) => {
    onClose(flag);
  };
  const headData = ['comment', 'Action', 'Created At'];

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '8px', fontSize: '28px', fontWeight: 700, color: '#19c25f' }}
        >
          Advertisement Comments
        </Typography>
        <TableContainer>
          <CustomTableView headData={headData}>
            {data.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
            {data?.map((e, index) => (
              <TableRow key={index}>
                <CustomTableCellForBody align="center">
                  <p style={tableCellPStyle}>{e?.comment}</p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="center">
                  <p style={tableCellPStyle}>{`${e.oldAction || 'N/A'} => ${e.newAction || 'N/A'}`}</p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="center">
                  <p style={tableCellPStyle}>
                    {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
                  </p>
                </CustomTableCellForBody>
              </TableRow>
            ))}
          </CustomTableView>
        </TableContainer>
        <div style={{ display: 'flex', gap: '12px', marginTop: '12px', justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
